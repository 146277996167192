<template>
  <WsMain>
    <WsRow>
      <WsCol
        v-for="(analytic,analyticIndex) in analytics"
        :key="analyticIndex"
        class="col-4"
      >
        <WsAnalytics
          :label="analytic.label"
          :value="analytic.value"
        ></WsAnalytics>
        <!-- <WsAnalyticsCard :title="analytics.price.title">
          <template v-slot:titleright>
            <WsStateSelect
              light
              class="flex-0"
              v-model="analytics.price.stage.range"
              :items="analytics.price.rangeItems"
            ></WsStateSelect>
          </template>
          <template v-slot:content>
            <WsText
              size="48"
              class="py-66"
              bold
              align="center"
            >{{analytics.price.value}}</WsText>
          </template>
        </WsAnalyticsCard> -->
      </WsCol>
      <!-- <WsCol class="col-4">
        <WsAnalyticsCard :title="analytics.people.title">
          <template v-slot:content>
            <WsText
              size="48"
              class="py-66"
              bold
              align="center"
            >{{analytics.people.value}}</WsText>
          </template>
        </WsAnalyticsCard>
      </WsCol> -->
    </WsRow>
  </WsMain>
</template>

<script>
export default {
  data: () => ({
    analytics: {
      accountsReceivableMonthly: {
        label: "本月應收",
        value: "$1545,000",
      },
      accountsPayableMonthly: {
        label: "訂單應付",
        value: "$301,500",
      },
      earningsMonthly: {
        label: "本月盈餘",
        value: "$1243,500",
      },
      humanScheduleWeekly: {
        label: "本週排班人次",
        value: "15人",
      },
      humanSpaceWeekly: {
        label: "本日未出班人次",
        value: "2人",
      },
      // price: {
      //   title: "訂單金額",
      //   value: "$801,352",
      //   stage: {
      //     range: "thismonth",
      //   },
      //   rangeItems: [
      //     {
      //       text: "本月份",
      //       value: "thismonth",
      //     },
      //     {
      //       text: "今年",
      //       value: "thisyear",
      //     },
      //     {
      //       text: "近7天",
      //       value: 7,
      //     },
      //     {
      //       text: "近30天",
      //       value: 30,
      //     },
      //     {
      //       text: "近90天",
      //       value: 90,
      //     },
      //   ],
      // },
      // people: {
      //   title: "總人數",
      //   value: "31",
      // },
    },
  }),
};
</script>

<style>
</style>